<template>
    <div class="quiz" v-if="translates && translates[langUrl]">
        <div v-if="start" class="quiz__start">
            <div class="quiz__start__container">
                <div class="quiz__start__logo"></div>
                <div class="quiz__start__text">
                    {{ translates[langUrl].page_0_title[lang] }}
                </div>
                <BaseButton class="quiz__start__button button-fill" @click="startProgress">{{ translates[langUrl].button_startProgress[lang] }}</BaseButton>
                <div class="quiz__start__comment">
                    {{ translates[langUrl].page_0_comment[lang] }}
                </div>
            </div>
        </div>
        <div v-if="mainContainer" class="quiz__main-container">
            <div class="quiz__logo"></div>
            <v-tabs-items v-model="tabs.current" class="quiz__main-container__items">
                <v-tab-item class="quiz__step" value="step_1">
                    <ValidationObserver ref="validator_1" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_1_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_1_comment[lang] }}</div>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="quiz.age"
                                type="Number"
                                :placeholder="translates[langUrl].placeholder_age[lang]"
                                :error-messages="errors[0]"
                                />
                        </ValidationProvider>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_2">
                    <ValidationObserver ref="validator_2" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_2_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_2_comment[lang] }}</div>
                        <v-radio-group
                            v-model="quiz.sex">
                            <QuizRadio 
                                :label="translates[langUrl].label_sex_male[lang]"
                                value="male"
                            />
                            <QuizRadio 
                                :label="translates[langUrl].label_sex_female[lang]"
                                value="female"
                            />
                            <QuizRadio 
                                :label="translates[langUrl].label_sex_other[lang]"
                                value="other"
                            />
                        </v-radio-group>
                        <BaseTextField
                            v-if="quiz.sex === 'other'"
                            v-model="quiz.sexOther"
                            :placeholder="translates[langUrl].placeholder_sex_other[lang]"
                            />
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_3">
                    <ValidationObserver ref="validator_3" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_3_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_3_comment[lang] }}</div>
                        <div class="quiz__checkbox-container wrap">
                            <template v-for="(item, index) in dictionaries.directions">
                                <QuizCheckbox
                                    v-model="quiz.directions"
                                    :key="index"
                                    :label="item.title"
                                    :value="item.code"
                                    class="wrap"
                                />
                            </template>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item  class="quiz__step" value="step_4">
                    <ValidationObserver ref="validator_4" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_4_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_4_comment[lang] }}</div>
                        <div class="quiz__checkbox-container wrap">
                            <template v-for="(item, index) in dictionaries.directionsAdditional">
                                <QuizCheckbox
                                    v-model="quiz.directionsAdditional"
                                    :key="index"
                                    :label="item.title"
                                    :value="item.code"
                                    class="wrap"
                                />
                            </template>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_5">
                    <ValidationObserver ref="validator_5" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_5_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_5_comment[lang] }}</div> 
                        <div class="quiz__checkbox-container wrap">
                            <template v-for="(item, index) in dictionaries.months">
                                <QuizCheckbox
                                    v-model="quiz.months"
                                    :key="index"
                                    :label="item.title"
                                    :value="item.code"
                                    class="wrap"
                                />
                            </template>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_6">
                    <ValidationObserver ref="validator_6" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_6_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_6_comment[lang] }}</div>
                        <template v-for="(item, index) in dictionaries.durations">
                            <QuizCheckbox
                                v-model="quiz.durations"
                                :key="index"
                                :label="item.title"
                                :value="item.code"
                                style="margin-right: 40px;"
                            />
                        </template>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_7">
                    <ValidationObserver ref="validator_7" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_7_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_7_comment[lang] }}</div>
                        <div class="quiz__checkbox-container wrap">
                            <template v-for="(item, index) in dictionaries.activities">
                                <QuizCheckbox
                                    v-model="quiz.activities"
                                    :key="index"
                                    :label="item.title"
                                    :value="item.code"
                                    class="wrap"
                                />
                            </template>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_8">
                    <ValidationObserver ref="validator_8" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_8_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_8_comment[lang] }}</div>
                        <div class="quiz__checkbox-container wrap">
                            <template v-for="(item, index) in dictionaries.comforts">
                                <QuizCheckbox
                                    v-model="quiz.comforts"
                                    :key="index"
                                    :label="item.title"
                                    :value="item.code"
                                    class="wrap"
                                />
                            </template>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_9">
                    <ValidationObserver ref="validator_9" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_9_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_9_comment[lang] }}</div>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="quiz.price"
                                :placeholder="translates[langUrl].placeholder_price[lang]"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <div class="quiz__description" v-html="translates[langUrl].page_9_description[lang]"></div>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_10">
                    <ValidationObserver ref="validator_10" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_10_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_10_comment[lang] }}</div>
                        <v-radio-group v-model="quiz.tripsCount">
                            <template v-for="(item, index) in dictionaries.tripsCount">
                                <QuizRadio
                                    :key="index"
                                    :label="item.title"
                                    :value="item.code"
                                    style="margin-bottom: 8px"
                                />
                            </template>
                        </v-radio-group>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_11">
                    <ValidationObserver ref="validator_11" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_11_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_11_comment[lang] }}</div>
                        <BaseTextField
                            v-model="quiz.userCity"
                            :placeholder="translates[langUrl].placeholder_userCity[lang]"
                            :error-messages="errors[0]"
                        />
                        <BaseTextField
                            v-model="quiz.userState"
                            :placeholder="translates[langUrl].placeholder_userState[lang]"
                            :error-messages="errors[0]"
                        />
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="quiz.userCountry"
                                :placeholder="translates[langUrl].placeholder_userCountry[lang]"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_12">
                    <ValidationObserver ref="validator_12" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_12_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_12_comment[lang] }}</div>
                        <BaseTextField
                            v-model="quiz.userProfession"
                            :placeholder="translates[langUrl].placeholder_userProfession[lang]"
                            :error-messages="errors[0]"
                        />
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_13">
                    <ValidationObserver ref="validator_13" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_13_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_13_comment[lang] }}</div>
                        <BaseTextArea
                            v-model="quiz.experience"
                            :placeholder="translates[langUrl].placeholder_experience[lang]"
                            :error-messages="errors[0]"
                        />
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_14">
                    <ValidationObserver ref="validator_14" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_14_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_14_comment[lang] }}</div>
                        <BaseTextArea
                            v-model="quiz.ideas"
                            :placeholder="translates[langUrl].placeholder_ideas[lang]"
                            :error-messages="errors[0]"
                        />
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_15">
                    <ValidationObserver ref="validator_15" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_15_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_15_comment[lang] }}</div>
                        <ValidationProvider v-slot="{ errors }" rules="required|email">
                            <BaseTextField
                                v-model="quiz.userEmail"
                                :placeholder="translates[langUrl].placeholder_userEmail[lang]"
                                :error-messages="errors[0]"
                                autocomplete="off"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                :placeholder="translates[langUrl].placeholder_userPhone[lang]"
                                :error-messages="errors[0]"
                                pattern="\d*"
                                v-model="quiz.userPhone"
                                autocomplete="off"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="quiz.userName"
                                :placeholder="translates[langUrl].placeholder_userName[lang]"
                                :error-messages="errors[0]"
                                autocomplete="off"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item class="quiz__step" value="step_16">
                    <ValidationObserver ref="validator_16" slim>
                        <h1 class="quiz__page-name">{{ translates[langUrl].page_16_title[lang] }}</h1>
                        <div class="quiz__comment">{{ translates[langUrl].page_16_comment[lang] }}</div>
                        <div class="quiz__personal-info">
                            <BaseCheckbox 
                                v-model="quiz.personalDataAgree"
                            />
                            <div class="quiz__personal-info__text" v-html="translates[langUrl].page_16_personalInfo[lang]">
                            </div>
                        </div>
                    </ValidationObserver>
                </v-tab-item>
            </v-tabs-items>
            <div class="quiz__progress">
                <div class="quiz__desktop-buttons">
                    <QuizButton
                        class="quiz__step-buttons__button quiz__step-buttons__button__back"
                        @click="back()"><img src="../../assets/ArrowPrev.svg" alt="back" style="margin-right: 10px">{{ translates[langUrl].button_back[lang] }}</QuizButton>
                    <div class="quiz__desktop-buttons__rigth-container">
                        <BaseButton
                            class="quiz__step-buttons__button quiz__step-buttons__button__next button-fill"
                            @click="next()">
                            <template v-if="step === tabs.items.length">
                                {{ translates[langUrl].button_send[lang] }}
                            </template>
                            <template v-else>
                                {{ translates[langUrl].button_next[lang] }} <img src="../../assets/ArrowNext.svg" alt="next" style="margin: 1px 0 0 10px">
                            </template>
                        </BaseButton>
                    </div>
                </div>
                <div class="quiz__progress-percent">{{ progress }}%</div>
                <v-progress-linear
                    class="quiz__progress-bar"
                    v-model="progress"
                ></v-progress-linear>
            </div>
        </div>
        <div v-if="complete" class="quiz__complete">
            <div class="quiz__complete__container">
                <div class="quiz__complete__check"></div>
                <div class="quiz__complete__text">{{ translates[langUrl].page_17_title[lang] }}!</div>
                <div class="quiz__complete__comment">
                    {{ translates[langUrl].page_17_comment[lang] }}
                </div>
            </div>
        </div>
        <v-tabs v-model="tabs.current" style="display: none">
            <v-tabs-slider />
            <v-tab
                v-for="item in tabs.items"
                :id="item"
                :key="item"
                :href="'#step_' + item"
                @click="validate(item)">
                <template v-if="validates['step_' + item] === true"><div class="validate-check validate-true"></div></template>
                <template v-else-if="validates['step_' + item] === false"><div class="validate-check validate-false"></div></template>
                <template v-else><div class="validate-check"></div></template>
            </v-tab>
        </v-tabs>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, checkUserProfile, translit } from '@/helpers';

    import BaseTextField from '../../components/common/BaseTextField.vue';
    import BaseTextArea from '../../components/common/BaseTextArea.vue'
    import BaseCheckbox from '../../components/common/BaseCheckbox.vue'
    import QuizCheckbox from '../../components/common/quiz/QuizCheckbox.vue';
    import BaseButton from '../../components/common/BaseButton.vue';
    import QuizButton from '../../components/common/quiz/QuizButton.vue';
    import BaseDatePicker from '../../components/common/BaseDatePicker.vue';
    import QuizRadio from '../../components/common/quiz/QuizRadio.vue';

    export default {
        name: 'Quiz',
        metaInfo: {
            title: 'Квиз'
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseTextField,
            BaseTextArea,
            BaseCheckbox,
            QuizCheckbox,
            QuizButton,
            BaseButton,
            BaseDatePicker,
            QuizRadio,
        },
        data: () => ({
            imageSrc,
            start: true,
            mainContainer: false,
            complete: false,
            tabs: {
                current: null,
                needValidate: 1,
                items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            },
            step: 1,
            validates: {
                step_1: null,
                step_2: null,
                step_3: null,
                step_4: null,
                step_5: null,
                step_6: null,
                step_7: null,
                step_8: null,
                step_9: null,
                step_10: null,
                step_11: null,
                step_12: null,
                step_13: null,
                step_14: null,
                step_15: null,
                step_16: null,
            },
            errors: {
                pictures: ''
            },
            isDesktop: false,
            personalDataAgree: false,
            progress: 7,
            russia: false,
            quiz: {
                blogger: '',
                age: '',
                sex: 'male',
                sexOther: '',
                directions: [],
                directionsAdditional: [],
                months: [],
                durations: [],
                activities: [],
                comforts: [],
                price: '',
                tripsCount: '',
                userCity: '',
                userState: '',
                userCountry: '',
                userProfession: '',
                experience: '',
                ideas: '',
                userEmail: '',
                userPhone: '',
                userName: '',
                personalDataAgree: false
            },
            langUrl: '/quiz/social'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('users', {
                blogger: state => state.entity
            }),
            ...mapState('quiz', ['dictionaries']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            await store.dispatch('users/fetchBySocial', { social: this.$route.params.social });
            this.quiz.blogger = this.blogger._id;
            await store.dispatch('quiz/dictionaries');
            this.quiz = {
                ...this.quiz,
            }
        },
        methods: {
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            startQuiz() {
                this.start = true;
                this.mainContainer = false;
                this.complete = false;
            },
            startProgress() {
                this.start = false;
                this.mainContainer = true;
                this.complete = false;
            },
            completeQuiz() {
                this.start = false;
                this.mainContainer = false;
                this.complete = true;
            },
            async validate(step) {
                const valid = await this.$refs['validator_' + this.tabs.needValidate].validate();
                this.validates['step_' + this.tabs.needValidate] = valid;
                this.tabs.needValidate = step;
                return valid;
            },
            async next() {
                if(this.step < this.tabs.items.length) {
                    const valid = await this.$refs['validator_' + (this.step)].validate();
                    if(valid) {
                        this.clearServerErrors();
                        this.progress = Math.round((this.step + 1)/this.tabs.items.length * 100)
                        this.tabs.current = 'step_' + (this.step + 1);
                        this.step++;
                    }
                } else if (this.quiz.personalDataAgree) {
                    this.save();
                    this.completeQuiz();
                }
            },
            async back() {
                if(this.step > 1) {
                    this.validate(this.step - 1)
                    this.progress = Math.round((this.step - 1)/this.tabs.items.length * 100)
                    this.tabs.current = 'step_' + (this.step - 1);
                    this.step--;
                } else {
                    this.progress = 7;
                    this.startQuiz();
                }
            },
            clearServerErrors() {
                this.serverErrors = {
                    code: ''
                };
            },
            async save() {
                store.commit('quiz/SET_ENTITY', {
                    ...this.quiz,
                    userPhone: '+' + this.quiz.userPhone.replace(/[^\d]/g, '')
                });
                await store.dispatch('quiz/save');
            },
            checkAllRussia(checked) {
                if(checked) {
                    this.quiz.directionsRussian = this.dictionaries.directionsRussian.map(item => item.code);
                } else {
                    this.quiz.directionsRussian = [];
                }
            } 
        },
    };
</script>

<style lang="scss">
.quiz {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    @media all and (max-width: 768px) {
        width: 100%;
    }
    &__logo {
        height: 30px;
        width: 260px;
        background: url(../../assets/Main-logo.svg);
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 60px;
        @media all and (max-width: 768px) {
            margin-bottom: 32px;
        }
    }
    &__tabs-outline {
        border-bottom: 1px solid #E5E6E9;
        margin: 32px 0 16px;
    }
    &__page-name {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        margin: 24px 0 12px;
        @media all and (max-width: 768px) {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
        }
    }
    &__comment {
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #616D89;
        @media all and (max-width: 768px) {
            font-weight: 600;
        }
    }
    &__description {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #616D89;
        margin-top: 40px;
    }

    &__start {
        height: 100vh;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        &__container {
            max-width: 445px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media all and (max-width:768px) {
                max-width: 100%;
                padding: 20px;
            }
        }
        &__logo {
            height: 30px;
            width: 260px;
            background: url(../../assets/Main-logo.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 32px;
        }
        &__text {
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            text-align: center;
            color: #273155;
            margin-bottom: 60px;
        }
        &__button {
            width: calc(100% - 40px);
            margin-bottom: 16px;
            height: 60px !important;
        }
        &__comment {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #A1A4B1;
        }
    }
    &__main-container {
        display: flex;
        flex-direction: column;
        // margin-bottom: 40px;
        width: 650px;
        height: 100%;
	    padding-top: 80px;
        padding-bottom: 160px;
        margin: 0 auto;
        @media all and (max-width: 768px) {
            width: 100%;
            padding: 20px 20px 160px;
        }
        &__items {
            width: 650px;
            height: 100%;
            // padding: 20px;
            background: #FFFFFF;
            // box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            // border-radius: 12px;
            margin: 0 auto;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
        &__menu {
            width: 325px;
            max-height: 700px;
            margin-left: 32px;
            padding: 20px 4px;
            background: #F7F9FA;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 12px;
            overflow: hidden;
            &__item {
                display: flex;
                flex-direction: column;
                padding: 10px;
                list-style-type: none;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #525B77;
                transition-duration: 1s;
                cursor: pointer;
                &_active {
                    color: #fff;
                    background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
                    border-radius: 12px 0 0 12px;
                    // opacity: .8;
                    transition-duration: 1s;
                }
            }
        }
    }
    &__complete {
        height: 100vh;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        &__container {
            max-width: 445px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media all and (max-width:1024px) {
                padding:  0 40px;
            }
        }
        &__check {
            height: 100px;
            width: 100px;
            background: url(../../assets/quiz-check.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 32px;
        }
        &__text {
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            text-align: center;
            color: #273155;
            margin-bottom: 12px;
        }
        &__comment {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #A1A4B1;
            @media all and (max-width:768px) {
                font-size: 18px;
                line-height: 140%;
            }
        }
    }
    &__checkboxes-container {
        display: flex;
        flex-direction: row;
        @media all and (max-width: 768px) {
            flex-direction: column;
        }
    }
    &__checkbox-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 305px;
        &.russian {
            margin-right: 40px;
        }
        &.wrap {
            width: 690px;
            margin-right: -40px;
            @media all and (max-width: 768px) {
                width: 100%;
                margin-right: 0;
            }
        }
        @media all and (max-width: 768px) {
            width: 100%;
        }
    }

    &__personal-info {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        .base-checkbox.v-input--selection-controls {
            margin: 0 !important;
        }

        &__text {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #616D89;
            text-align: left;
            a {
                font-weight: 500;
                color: black;
                text-decoration: underline;
            }
            @media all and (max-width: 768px) {
                font-weight: 300;
                font-size: 14px;
            }
        }
    }
    &__step {
        height: 100%;
        padding-bottom: 20px;
        span {
            height: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
    }
    &__step-buttons {
        // @media all and (min-width: 768px) {
        //         display: none;
        //     }
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        margin-top: 4px;
        width: 100%;
        &__button {
            width: 159px;
            height: 48px;
            margin-bottom: 12px;
            background: #FFFFFF;
            color: #DA0A63;
            &.moderate {
                min-width: 100% !important;
                @media all and (min-width: 768px) {
                    width: 280px;
                }
            }
            &__back {
                width: 180px;
                @media all and (max-width: 768px) {
                    width: 115px;
                }
            }
            &__next {
                width: 440px;
                @media all and (max-width: 768px) {
                    width: 195px;
                }
            }
        }
    }
    &__save {
        background: linear-gradient(270deg, rgba(230, 30, 77, 0.1) 0%, rgba(230, 30, 77, 0.1) 100%), #FFFFFF;
        width: 100%;
        border: none !important;
    }
    &__desktop-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        bottom: 80px;
        width: 650px;
        padding-top: 20px;
        background: #FFFFFF;
        @media all and (max-width: 768px) {
            width: calc(100% - 40px);
        }
        &__rigth-container {
            display: flex;
            flex-direction: row;
            .v-btn {
                margin-left: 20px;
            }
        }
        &__save {
            width: 230px;
            background: linear-gradient(270deg, rgba(230, 30, 77, 0.1) 0%, rgba(230, 30, 77, 0.1) 100%), #FFFFFF;
            border: none !important;
        }
    }
    &__block-name {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155;
        padding: 8px 0 12px;
    }
    &__input-container{
        display: flex;
        min-height: 56px;
        width: 100%;
        &__line {
            display: flex;
            flex-direction: row;
        }
        &__input-name{
            display: none;
            @media all and (min-width: 768px) {
                display: flex;
                min-width: 185px;
                max-width: 185px;
                margin-right: 40px;
                color: #65676E;
                padding-top: 18px;
            }
        }
        &__input-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            @media all and (min-width: 768px) {
                flex-direction: row;
            }
            &__tour-type {
                width: 100%;
                flex-direction: column;
            }
            &__list {
                width: 100%;
                flex-direction: column;

                > div {
                    padding-bottom: 20px;    
                }
                .v-textarea.base-text-area {
                    margin-bottom: 5px !important;
                }
                .delete-button__container {
                    margin-top: 5px;
                }
            }
        }
    }
    &__form-card {
    padding-top: 28px;
        &:first-of-type {
            padding-top: 0;
        }
        &__delete-button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        &__outline {
            border-bottom: 0.5px solid #E5E6E9;
            padding: 28px 0 4px;
            margin-bottom: 20px;
        }
    }
    &__radio-group {
        margin-top: 0;
        padding-bottom: 8px;
        @media all and (min-width: 768px) {
            margin-top: 10px;
            &.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
            margin-bottom: 0px;
            }
        }
        
    }
    &__span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        letter-spacing: -0.02em;
        color: #A2A5AE;
        margin-left: 3px;
    }
    &__info {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        @media all and (min-width: 768px) {
            margin-left: 225px;
        }

        &__icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
        &__text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #A2A5AE;
            max-width: 79%;
        }
        p {
            margin: 0;
        }
    }
    &__progress {
        position: fixed;
        width: 650px;
        bottom: 0;
        height: 80px;
        padding: 16px 0 32px;
        background: #FFFFFF;
        z-index: 10;
        @media all and (max-width: 768px) {
            width: calc(100% - 40px);
        }
    }
    &__progress-percent {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 13px;
        color: #273155;
        margin-bottom: 12px;
    }
    &__progress-bar {
        height: 6px !important;
        border-radius: 3px;
        .v-progress-linear__buffer {
            height: 6px;
        }
    }
    .buttons {
	display: flex;
	flex-direction: column;
    }
}
</style>